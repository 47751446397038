import React, { useState } from 'react';

import '../css/MultipleChoiseQuestion.css'

function MultipleChoiceQuestion({ questionTitle, options, name, image, onAnswerChange }) {
  const [selectedOption, setSelectedOption] = useState(''); 
  
  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    onAnswerChange(event);
  };

  return (
    <div className="form-section">
      <h3 className="mainbox-title">{questionTitle}</h3>
      <img className="question-image" src={image} alt="Question" />
      <div className='choices'>
        {options.map((option, index) => (
          <label className={`choice-container ${selectedOption === option ? 'selected' : ''}`} key={index}>
            <input
              type="radio"
              className="radio-input"
              name={name}
              value={option}
              checked={selectedOption === option}
              onChange={handleOptionChange}
            />
            <span className="choice-text">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default MultipleChoiceQuestion;
