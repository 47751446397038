import React from 'react'
import queryString from 'query-string'
import './css/claim.css'

export default class Claim extends React.Component {
  constructor() {
    super()
    this.state = {
      formValue: {},
      status: 'ok',
      // TODO: add support for i18n
      message: 'This prize could not be claimed',
      // hardcoded params (we will inherit from the prize page context in the future)
      termsUrl: 'https://apollo18.com/wp-content/uploads/2020/09/Information-obligations-under-Art_13-DSGVO-for-prize-draws.pdf',
    }

    this.handleChange = this.handleChange.bind(this);
    this.doIt = this.doIt.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const formValue = this.state.formValue;
    formValue[name] = value;
    this.setState({ formValue });
  }

  componentDidMount() {
    this.queryParams = queryString.parse(this.props.location.search)
    let host = this.getHost()
    if (!this.queryParams.token) {
      this.setState({ status: 'error', message: 'No token specified' })
      return
    }

    let prizeId = this.getPrizeId(this.queryParams.token)
    fetch(`https://${host}/api/prizes/${prizeId}/status`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    }).then((responseBodyJson) => {
      if (responseBodyJson.status === 'claimed') {
        this.setState({ status: 'claimed' })
      }
    })
  }

  doIt(event) {
    event.preventDefault();
    const formValue = this.state.formValue
    const host = this.getHost()
    const prizeId = this.getPrizeId(this.queryParams.token)
    return fetch(`https://${host}/api/prizes/${prizeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        meta: {
          ...formValue
        },
        prizeToken: this.queryParams.token,
      }),
    }).then((response) => {
      if (!response.ok) {
        this.setState({ status: 'error' })
      } else {
        this.setState({ status: 'returned' })
      }
    })
  }

  getPrizeId(token) {
    const split = token.split(':')
    if (split.length === 2) {
      return split[0]
    }
    return ''
  }

  getHost() {
    return this.isStaging() ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  }

  canBeSubmitted() {
    const { email, name, termsAccepted } = this.state.formValue;
    let emailIsValid = true;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      emailIsValid = false;
    }

    return (
      emailIsValid &&
      name?.length > 0 &&
      termsAccepted
    );
  }

  render() {
    const isEnabled = this.canBeSubmitted();
    if (this.state.status === 'returned') {
      return (
        <div id="content">
          <p className="mainbox-title squeeze">Thanks for sending in your information. If you have won a Hextech Chest code, we will inform you by email within 72 hours after the current tournament stage.</p>
        </div>
      )
    } else {
      return (
        <div id="content">
          <img className="mainbox-header" src="./header.jpg" alt="header" />
          <h3 className="mainbox-title">Take part in the raffle</h3>
          <p className="mainbox-description">
          Thanks for sending in your information. If you have won a Hextech Chest Code, we will inform you via. mail after the current tournament stage - please understand that this period may be slightly extended by weekends or public holidays. Please whitelist our address mb-hextech-chest-quiz@apollo18.com and check your spam folders.
          </p>
          <p className="mainbox-description">
          Please Note: You're taking part in a raffle. A win of a Hextech Chest Code is not guaranteed. If you win, you'll receive a mail from us.
          </p>
          <form action="post" className="mainbox-form" onSubmit={this.doIt}>
            <div className="input-group">
              <input value={this.state.value} name="name" type="text" placeholder="Your Name" onChange={this.handleChange} />
            </div>
            <div className="input-group">
              <input value={this.state.value} name="email" type="email" placeholder="Your E-Mail Address" onChange={this.handleChange} />
            </div>
            <div className="input-group">
              <label className="mc-checkbox">
                <span className="fix-hyphen">
                  I consent to receiving one promotional email from Mercedes following the conclusion of the Worlds tournament.
                </span>
                <input type="checkbox" name="consented" value="true" onChange={this.handleChange} />
                <span className="mc-checkmark"></span>
              </label>
            </div>

            {/* errors */}
            {this.state.status === 'claimed' &&
              <div id="secondJoinRow" style={{ color: 'red' }}>
                This prize is already claimed.
              </div>
            }
            {this.state.status === 'returned' &&
              <div id="secondJoinRow">
                You have now claimed the prize.
              </div>
            }
            {this.state.status === 'error' &&
              <div id="secondJoinRow" style={{ color: 'red' }}>
                {this.state.message}
              </div>
            }
            {/* submit */}
            <div style={{ marginTop: "30px" }}>
              <label className="mc-checkbox">
                <span className="fix-hyphen">
                  By submitting your personal data to claim your prize, you confirm that you have read and agree to the
                  <a style={{ marginLeft: "4px" }} href={this.state.termsUrl} target="termsandconditions" rel="nofollow noopener noreferrer">information obligations under Art. 13 DSGVO for prize draws from the data-receiving body Apollo18 GmbH</a>.
                </span>
                <input type="checkbox" name="termsAccepted" value="true" onChange={this.handleChange} />
                <span className="mc-checkmark"></span>
              </label>
            </div>
            <input type="submit" className="custom-button" disabled={!isEnabled} value={'Claim Prize'} />
            <p className="mainbox-trailing">All prizes will be distributed by Apollo18 GmbH. We will only use your data to fulfill the terms of the competition. Any further use of your data for advertising or marketing purposes is strictly prohibited. Your data will be deleted 20 days after the distribution of prizes at the latest.</p>
          </form>
        </div>
      )
    }
  }

  isStaging() {
    return window.location.hostname.includes('staging')
  }
}
