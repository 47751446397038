export const isEmailValid = (email) => {
  const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(email)
}
export const isStaging = () => {
  return window.location.hostname.includes('staging')
}

export const isLocalhost = () => {
  return window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')
}

export const getHost = () => {
  return isStaging() || isLocalhost()
    ? 'console.staging.stagecast.io' 
    : 'console.stagecast.io'
}

export const answerQuestion = (questionData) => {
  const host = getHost()
  return fetch(`https://${host}/api/formsubmit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({...questionData}),
    })
}

export const conditionllyRedirectToActiveQuestion = ({ redirectTo } = {}) => {
  if (redirectTo) {
    window.location.href = `/formsubmit?challenge=${redirectTo}`
  }
  return null
}