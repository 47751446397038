import React from 'react'
import './css/claim.css'
import queryString from 'query-string'

export default class Claimed extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 'enter your workspace identifier',
      workspace: '',
      status: 'ok',
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.doIt = this.doIt.bind(this)
    this.keyUp = this.keyUp.bind(this)
  }

  handleChange(event) {
    let value = event.target.value
    this.setState({value: value})
  }

  clearInput() {
    this.setState({value: ''})
  }

  keyUp(event) {
    if (event.keyCode === 13) {
      this.doIt()
    }
  }

  componentDidMount() {
    this.queryParams = queryString.parse(this.props.location.search)
  }

  doIt() {
    let workspaceId = this.state.value.trim()
    let subHost = this.isStaging() ? '.staging' : ''
    window.open(`https://console${subHost}.stagecast.io/api/prizes/workspaces/${workspaceId}/claims`)
  }

  render() {
    return (<div id="content">
              <h3 className="mainbox-title">Type your workspace identifier</h3>
                <div className="input-group mainbox-form">
                  <input value={this.state.value}
                        type="text"
                        onChange={this.handleChange}
                        onKeyUp={this.keyUp}
                        onFocus={this.clearInput} />
                </div>
                <input className="custom-button" type="submit" onClick={this.doIt} value={'Fetch'}/>
              {this.state.status === 'ok' &&
               <div id="secondJoinRow">
                  <p className="mainbox-trailing">Specify the event identifier of your event.</p>
               </div>
              }
            </div>)
  }

  isStaging() {
    return window.location.hostname.includes('staging')
  }
}
