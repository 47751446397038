import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import queryString from 'query-string'

import MultipleChoiceQuestion from './MultipleChoiceQuestion';

import { answerQuestion, isEmailValid, conditionllyRedirectToActiveQuestion } from '../utils'
import { getQuestion, pageContent } from './page-content'

import '../css/claim.css'

export default class FormSubmitCard extends React.Component {
  constructor(props) {
    super(props)
    this.queryParams = queryString.parse(props.location.search)
    this.captchaRef = React.createRef();
    const { value, error } = getQuestion(this.queryParams.challenge)
    conditionllyRedirectToActiveQuestion(value)
    this.state = {
      formValue: {
        question: this.queryParams.challenge,
      },
      status: 'ok',
      message: '',
      isLoading: false,
      pageStatus: error ? 'failed' : null,
      questionId: this.queryParams.challenge,
      question: value,
      shouldLog: this.queryParams.logs === 'true',
      termsUrl: 'https://apollo18.com/wp-content/uploads/2020/09/Information-obligations-under-Art_13-DSGVO-for-prize-draws.pdf',
      competitionTermsUrl: '../Worlds2023_Terms-and-conditions-competition_The-Hunt-for-Glory.pdf'
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const formValue = this.state.formValue;
    formValue[name] = value;
    this.setState({ formValue });
    if (this.state.shouldLog) {
      console.log(this.state.formValue);
    }
  }

  handleRecaptchaChange(userVerificationToken) {
    this.setState({ formValue: {...this.state.formValue, recaptchaResponse: userVerificationToken } });
    if (this.state.shouldLog) {
      console.log(this.state.formValue);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true })
    const formValue = this.state.formValue
    return answerQuestion(formValue).then((response) => {
      this.captchaRef.current.reset();
      if (!response.ok) {
        this.setState({ isLoading: false, pageStatus: 'network_error', errorMessage: 'An error occurred while submitting your information.\nTry again later.' })
      } else {
        this.setState({ isLoading: false, pageStatus: 'finished' })
      }
    })
  }

  // rendering functions
  getChallengeSchedule () {
    return (
      <ul className='mainbox-text unstyled-list'>
        {
          pageContent.intro.challenges
            .map(
              (item, key) =>
                (<li key={key} style={{fontWeight: item.id === this.state.questionId ? 'bold' : ''}}>
                  {item.value}
                </li>)
              )
        }
      </ul>
    )
  }

  canBeSubmitted() {
    const { email, name, answer, termsAccepted, recaptchaResponse } = this.state.formValue;
    let emailIsValid = isEmailValid(email);

    return (
      recaptchaResponse &&
      emailIsValid &&
      answer &&
      name?.length > 2 &&
      termsAccepted
    );
  }

  getErrors () {
    if (this.state.pageStatus === 'network_error') {
      return (
        <div className="error-message" style={{ color: 'red' }}>
          {this.state.errorMessage}
        </div>
      )
    }
  }

  render() {
    const isEnabled = this.canBeSubmitted();
    if (this.state.pageStatus === 'finished') {
      return (
        <div id="content">
          <p className="mainbox-title squeeze text-center withNewlines">{pageContent.confirmationMessage}</p>
        </div>
      )
    } else if (this.state.pageStatus === 'failed') {
      return (
        <div id="content">
          <p className="mainbox-title squeeze text-center">There is nothing to see here!<br/>Try again later.</p>
        </div>
      )
    } else {
      return (
        <div id="content">
          {/*intro*/}
          <section id="intro">
            <img className="mainbox-header" src={pageContent.intro.header} alt="header" />
            <h3 className="mainbox-title">{pageContent.intro.title}</h3>
            <p className="mainbox-description">{pageContent.intro.description}</p>
            <div className="mainbox-text">{pageContent.intro.listTitle}</div>
            {this.getChallengeSchedule()}
          </section>
          {/*main*/}
          <section id="main">
            <form action="post" className="mainbox-form" onSubmit={this.handleSubmit}>
              <MultipleChoiceQuestion
                name="answer"
                {...this.state.question}
                onAnswerChange={this.handleChange}
              />
              <div className="form-section" style={{marginTop: '48px'}}>
                {/*userinfo*/}
                <h3 className="mainbox-title">{pageContent.mainBox.title}</h3>
                <div className="input-group">
                  <input value={this.state.value} name="name" type="text" placeholder="Your Name" onChange={this.handleChange} />
                </div>
                <div className="input-group">
                  <input value={this.state.value} name="email" type="email" placeholder="Your E-Mail Address" onChange={this.handleChange} />
                </div>
                {/* terms */}
                <div>
                  <label className="mc-checkbox">
                    <span className="fix-hyphen">
                      {pageContent.mainBox.terms}
                      <a style={{ marginLeft: "4px" }} href={this.state.termsUrl} target="termsandconditions" rel="nofollow noopener noreferrer">{pageContent.mainBox.termsTitle}</a>
                      &nbsp;and
                      <a style={{ marginLeft: "4px" }} href={this.state.competitionTermsUrl} target="competitionterms" rel="nofollow noopener noreferrer">{pageContent.mainBox.competitionTermsTitle}</a>.

                    </span>
                    <input type="checkbox" name="termsAccepted" value="true" onChange={this.handleChange} />
                    <span className="mc-checkmark"></span>
                  </label>
                  <label className="mc-checkbox">
                    <span className="fix-hyphen">{pageContent.mainBox.marketingConsent}
                    </span>
                    <input type="checkbox" name="consented" value="true" onChange={this.handleChange} />
                    <span className="mc-checkmark"></span>
                  </label>
                </div>
              </div>
              <ReCAPTCHA
                className='g-recaptcha'
                sitekey="6LfCgaIoAAAAAH3Ipl_7aKPVJM0eAEglsDQSvlJ6"
                ref={this.captchaRef}
                onChange={this.handleRecaptchaChange}
              />
              {this.getErrors()}
              <input type="submit" className="custom-button" disabled={!isEnabled || this.state.isLoading} value={pageContent.mainBox.submitButton} />
              <p className="mainbox-trailing">{pageContent.mainBox.disclaimer}</p>
            </form>
          </section>
        </div>
      )
    }
  }
}
