export const questions = {
  '101925': {
    start: new Date('2023-10-19').getTime(),
    end: new Date('2023-10-26').getTime(),
    redirectTo: '110210',
    questionTitle: 'Which emote do you see?',
    image: '../hunt_for_glory_game_1.jpg',
    options: [
      'Catch me if you can',
      'Let\'s do this',
      'Gonna be a blast'
    ]
  },
  '102601': {
    start: new Date('2023-10-26').getTime(),
    end: new Date('2023-11-02').getTime(),
    redirectTo: '110210',
    questionTitle: 'Where is the emote “Squink”?',
    image: '../hunt_for_glory_game_2.jpg',
    options: [
      'On the wall',
      'In the car',
      'On the lantern'
    ]
  },
  '110210': {
    start: new Date('2023-11-02').getTime(),
    end: new Date('2023-11-11').getTime(),
    questionTitle: 'How many minions are hiding?',
    image: '../hunt_for_glory_game_3.jpg',
    options: [
      '2-3',
      '4-5',
      '6-7'
    ]
  },
  '111119': {
    start: new Date('2023-11-11').getTime(),
    end: new Date('2023-11-20').getTime(),
    questionTitle: 'Where is the Summoner\'s Cup located?',
    image: '../hunt_for_glory_game_4.jpg',
    options: [
      'On the driver seat',
      'On the back seat',
      'In the Trunk'
    ]
  }
}

export const getQuestion = (id) => {
  return questions[id]
    ? { value: questions[id] }
    : { error: 'missing_question' }
}

export const pageContent = {
  confirmationMessage: 'Thanks for participating in the Hunt for Glory!\n\nIn case you submitted the correct answer and won in the raffle, you will receive an email from us within 72 hours after the current challenge.\n\nQuick reminder: Make sure you participate in at least three out of four challenges for a chance to win more prizes like epic LoL skins!',
  intro: {
    title: 'Welcome to the Hunt for Glory!',
    header: '../hunt_for_glory_header.gif',
    description: 'In every Challenge you get the chance to take part in a raffle of 2,000 Hextech Chests. Furthermore, we want to reward the most loyal fans of the Mercedes-Benz Hunt for Glory with 2,000 epic skins. To participate, all you have to do is to solve 3 of 4 challenges during Worlds 2023.',
    listTitle: 'Participate in:',
    challenges: [
      { id: '101925', value: 'Challenge 1: Oct 19 - Oct 25' },
      { id: '102601', value: 'Challenge 2: Oct 26 - Nov 01' },
      { id: '110210', value: 'Challenge 3: Nov 02 - Nov 10' },
      { id: '111119', value: 'Challenge 4: Nov 11 - Nov 19' }
    ]
  },
  mainBox: {
    title: 'Please enter your information so that we can contact you in case you won a prize',
    terms: 'By submitting your personal data to claim your prize, you confirm that you have read and agree to the',
    termsTitle: 'information obligations under Art. 13 DSGVO for prize draws from the data-receiving body Apollo18 GmbH',
    competitionTermsTitle: 'the competition terms',
    marketingConsent: 'I consent to receiving one promotional email from Mercedes following the conclusion of the LoL Worlds campaign.',
    submitButton: 'Submit',
    disclaimer: 'All prizes will be distributed by Apollo18 GmbH. We will only use your data to fulfill the terms of the competition. Any further use of your data for advertising or marketing purposes is strictly prohibited. Your data will be deleted 20 days after the distribution of prizes at the latest.'
  }
}
