import React from 'react'
import './css/claim.css'
import queryString from 'query-string'

export default class Unsubscribed extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    return (<div id="content">
              <h3 className="mainbox-title">You have unsubscribed successfully</h3>
            </div>)
  }
}
