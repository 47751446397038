import React from 'react'
import Claim from './claim'
import Claimed from './claimed'
import Unsubscribed from './unsubscribed'
import FormSubmitCard from './components/FormSubmitCard'
import './css/App.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

function App() {
  const background = window.location.pathname.includes('formsubmit') ? 'formsubmit' : ''
  return (
    <div className={`App ${background}`}>
      <header className="App-header">
        <Router>
          <Switch>
            <Route path="/formsubmit" component={FormSubmitCard}/>
            <Route path="/unsubscribed" component={Unsubscribed}/>
            <Route path="/claimed" component={Claimed}/>
            <Route path="/" component={Claim}/>
          </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;
